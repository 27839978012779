.container-cookies.hide {
    display: none !important;
}

.box-cookies {
    position: absolute;
    background: var(--white-color);
    top: 0px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    height: max-content;
    border-radius: 10px;
    margin: auto;
    padding: 40px 92px;
    max-width: 1111px;
}

.box-cookies .msg-cookies {
    color: var(--text-color);
    font: normal normal normal 16px/22px Open Sans;
    text-align: center;
}

.box-cookies .btn-cookies-cancel {
    background: var(--white-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.box-cookies .btn-cookies-accept {
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
    color: var(--white-color);
    border: none;
}

.box-cookies .btn-cookies {
    cursor: pointer;
    align-self: normal;
    font: normal normal bold 20px/27px Open Sans;
    border-radius: 30px;
    height: 60px;
    margin-left: 42px;
    padding: 0 61px;
}

@media screen and (max-width: 800px) {
    .box-cookies {
        flex-direction: column;
    }
    .box-cookies .btn-cookies {
        margin: 10px 5px;
    }
}

@media screen and (max-width: 480px) {
    .box-cookies {
        div {
           display: block;
            width: 100%;
        }
        .btn-cookies {
            width: 100%;
            margin: 0;
            margin-top: 10px !important;
        }
    }
}

.container-cookies {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.49) 0% 0% no-repeat padding-box;
    border: 1px solid var(--color-cecece-o3);
    border: 1px solid --border-color-2;
    z-index: 9991;
}

