header{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 9990;
    transition: var(--transition);
    background-color: var(--white-color);
}

header.shadow {
    background: var(--white-color) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000017;
    height: 76px;

}

header .logo{
    height: 50px;
    width: auto;
}

header .header-actions ul li+li {
    margin-left: 40px;
}

.heart {
    color: var(--primary-color);
}

header {
    .social-networks {
        font-size: 20px;
        margin-left: 65px;
        li {
            margin-right: 35px;
        }
    }
    .header-actions{
        font-size: 20px;
    }
}

.main-navigation ul li a {
    display: block;
    padding: 15px 20px;
    font-weight: 700;
}

.main-navigation {
    background-color: var(--white-color);
    position: fixed;
    top: 0;
    right: -600px;
    bottom: 0;
    max-width: 400px;
    width: 100%;
    transition: all .4s ease-in-out 0s;
    padding-top: 30px 24px;
}

.main-navigation.active {
    right: 0;
}

.main-navigation .close-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9;
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    body {
        padding-top: 50px;
    }
    .main-navigation ul li a {
        font-size: 20px;
    }
}