html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; } article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; } body { line-height: 1; } ol, ul { list-style: none; } blockquote, q { quotes: none; } blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; } table { border-collapse: collapse; border-spacing: 0; }

* {
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  font-size: 15px;
  color: var(--text-color);
}

body, input, select, option, textarea, button {
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 13px;
  }
}

*,
*::after,
*::before {
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
}

img {
  display: block;
  max-width: 100%;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited:hover {
  color: var(--primary-color);
}

a.h-black:hover,
a.h-black:visited:hover {
  color: var(--black-color);
}

a.h-white:hover,
a.h-white:visited:hover {
  color: var(--white-color);
}

b,
strong,
.bold {
  font-weight: 700;
}

.bolder {
  font-weight: 800;
}

.italic {
  font-style: italic !important;
}

h1 {
  font-size: 2em;
}

.big {
  font-size: 3.4em;
  line-height: 1.4em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1.1em;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  line-height: 1.2em;
}

h1.thin, h2.thin, h3.thin, h4.thin, h5.thin, h6.thin {
  font-weight: 400;
}

h1.small {
  font-size: 1.6em;
}

p {
  line-height: 1.6em;
  color: var(--text-color);
}

p:not(:last-of-type) {
  margin-bottom: 20px;
}

hr {
  border-top: 1px dashed var(--border-color-o3);
}

hr.line {
  border-top: 1px solid var(--border-color-o3);
}

.main-navigation hr {
  border-top: 1px solid #e2e2e2;;
}

.input-group {
  position: relative;
}

label {
  font-size: 0.8em;
  padding: 0 5px;
  background-color: var(--white-color);
}

label.normal {
  font-size: 1em;
}

.input-group label {
  position: absolute;
  top: -7px;
  left: 35px;
  z-index: 1;
}

input:not([type="range"]),
.select-form-default {
  width: 100%;
  height: 81px;
  border: 1px solid var(--border-color-o3);
  border-radius: 10px;
  padding: 26px 40px;
  transition: var(--transition);
}

textarea {
  width: 100%;
  border: 1px solid var(--border-color-o3);
  border-radius: 40px;
  padding: 10px 20px;
  transition: var(--transition);
  resize: none;
}

select {
  width: 100%;
  height: 64px;
  appearance: none;
  -webkit-appearance: none;
  padding: 15px;
  padding-left: 18px;
  background-color: transparent;
  border: 1px solid var(--border-color-o3);
  border-radius: 10px;
  transition: var(--transition);
}

.select-group {
  position: relative;
}

.select-group .icon {
  display: block;
  font-size: 14px;
  height: 14px;
  font-family: icomoon;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  margin: auto 0;
  z-index: -1;
}

input[type="radio"] {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  border: 3px solid transparent;
  box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.3);
  appearance: none;
  -webkit-appearance: none;
}

input[type="radio"]:hover {
  box-shadow: 0px 0px 0px 1px var(--primary-color);
  border: 0;
}

input[type="radio"]:checked {
  border: 3px solid var(--white-color);
  background-color: var(--primary-color);
}

input:not([type="range"]):hover,
select:hover,
input:not([type="range"]):focus,
select:focus {
  border: 1px solid var(--primary-color);
}

.group-checkbox {
  display: block;
  position: relative;
  padding: 5px 0 0 45px;
  cursor: pointer;
  font: normal normal normal 16px/22px Open Sans;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 30px;
  line-height: 20px;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background: var(--white-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--light-grey-3);
  border-radius: 10px;
}

.group-checkbox:hover input[type="checkbox"] ~ .checkmark {
  border-color: var(--primary-color);
}

.group-checkbox input[type="checkbox"]:checked ~ .checkmark {
  border-color: var(--primary-color);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.group-checkbox input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

.group-checkbox .checkmark:after {
  left: 10px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid var(--primary-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container {
  max-width: 1380px;
  margin: 0 auto;
  padding: 0 20px;
}

.container.large {
  max-width: 1640px;
}

.container.small {
  max-width: 1250px;
}

.light-bg {
  background-color: var(--light-bg-color);
}

.w600 {
  max-width: 600px;
  margin: 0 auto;
}

.w800 {
  max-width: 800px;
  margin: 0 auto;
}

.w900 {
  max-width: 900px;
  margin: 0 auto;
}

.w1100 {
  max-width: 1100px;
  margin: 0 auto;
}

.lt-pd {
  padding: 10px 20px;
}

.sm-pd {
  padding: 20px;
}

.md-pd {
  padding: 40px 20px;
}

.bg-pd {
  padding: 80px 20px;
}

.block {
  display: block;
}

.grid {
  display: grid;
}

.lt-gap {
  grid-gap: 10px;
}

.lt-gap-15 {
  grid-gap: 15px;
}

.sm-gap {
  grid-gap: 20px;
}

.sm-gap-30 {
  grid-gap: 30px;
}

.md-gap {
  grid-gap: 40px;
}

.id-gap {
  grid-gap: 50px;
}

.bg-gap {
  grid-gap: 80px;
}

.bg160-gap {
  grid-gap: 160px;
}

.id-cgap {
  column-gap: 50px;
}

.col-16 {
  grid-template-columns: repeat(16, 1fr);
}

.col-14 {
  grid-template-columns: repeat(14, 1fr);
}

.col-12 {
  grid-template-columns: repeat(12, 1fr);
}

.col-11 {
  grid-template-columns: repeat(11, 1fr);
}

.col-10 {
  grid-template-columns: repeat(10, 1fr);
}

.col-9 {
  grid-template-columns: repeat(9, 1fr);
}

.col-8 {
  grid-template-columns: repeat(8, 1fr);
}

.col-7 {
  grid-template-columns: repeat(7, 1fr);
}

.col-6 {
  grid-template-columns: repeat(6, 1fr);
}

.col-5 {
  grid-template-columns: repeat(5, 1fr);
}

.col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.col-1 {
  grid-template-columns: repeat(1, 1fr);
}

.span-13 {
  grid-column: span 13;
}

.span-12 {
  grid-column: span 12;
}

.span-11 {
  grid-column: span 11;
}

.span-10 {
  grid-column: span 10;
}

.span-9 {
  grid-column: span 9;
}

.span-8 {
  grid-column: span 8;
}

.span-7 {
  grid-column: span 7;
}

.span-6 {
  grid-column: span 6;
}

.span-5 {
  grid-column: span 5;
}

.span-4 {
  grid-column: span 4;
}

.span-3 {
  grid-column: span 3;
}

.span-2 {
  grid-column: span 2;
}

.ai-gc {
  align-items: center;
}

.ai-ge {
  align-items: end;
}

.ai-gss {
  align-items: start;
}

.ai-gs {
  align-items: stretch;
}

.as-ge {
  align-self: end;
}

.as-gc {
  align-self: center;
}

.as-gs {
  align-self: start;
}

.ji-gc {
  justify-items: center;
}

.ji-ge {
  justify-items: end;
}

.ji-gss {
  justify-items: start;
}

.flex {
  display: flex;
}

.fw-w {
  flex-wrap: wrap;
}

.fd-fc {
  flex-direction: column;
}

.ai-fc {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}

.ai-fs {
  align-items: flex-start;
}

.jc-sb {
  justify-content: space-between;
}

.jc-fs {
  justify-content: flex-start;
}

.jc-fc {
  justify-content: center;
}

.jc-fe {
  justify-content: flex-end;
}

.js-fc {
  justify-self: center;
}

.js-fe {
  justify-self: end;
}

.no-shr {
  flex-shrink: 0;
}

.va-m {
  vertical-align: middle;
}

.desktop-none {
  display: none;
}

@media screen and (max-width: 1024px) {
  .col-16,
  .col-14,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3 {
    grid-template-columns: 1fr 1fr;
  }

  .span-13,
  .span-12,
  .span-11,
  .span-10,
  .span-9,
  .span-8,
  .span-7,
  .span-6,
  .span-5,
  .span-4,
  .span-3{
    grid-column: span 2;
  }

  .tablet-none {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .col-16,
  .col-14,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2 {
    grid-template-columns: 1fr;
  }

  .span-13,
  .span-12,
  .span-11,
  .span-10,
  .span-9,
  .span-8,
  .span-7,
  .span-6,
  .span-5,
  .span-4,
  .span-3,
  .span-2 {
    grid-column: span 1;
  }

  .mobile-none {
    display: none;
  }

  .desktop-none {
    display: block;
  }

  input:not([type="range"]),
.select-form-default {
  width: 100%; //50% if 2 columns
  font-size: 1.25em;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 150px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.ph-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-0 {
  padding: 0;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.relative {
  position: relative;
}

.sm-text {
  font-size: 0.8em;
}

.mid-text {
  font-size: 1.3em;
}

.big-text {
  font-size: 1.6em;
}

.gg-text {
  font-size: 2em;
}

.fb-12 {
  font: normal normal bold 0.75em/1rem Open Sans;
}

.fn-14 {
  font: normal normal normal 0.875em/1.187rem Open Sans;
}

.f300-14 {
  font: normal normal 300 0.875em/1.187rem Open Sans;
}

.fb-14 {
  font: normal normal bold 0.875em/1.187rem Open Sans;
}

.fn-16 {
  font: normal normal normal 1.000em/1.375rem Open Sans;
}

.fb-16 {
  font: normal normal bold 1.000em/1.375rem Open Sans;
}

.fn-18 {
  font: normal normal normal 1.125em/1.500rem Open Sans;
}

.f600-18 {
  font: normal normal 600 1.125em/1.500rem Open Sans;
}

.fb-18 {
  font: normal normal bold 1.125em/1.500rem Open Sans;
}

.fn-20 {
  font: normal normal normal 1.250em/1.688rem Open Sans;
}

.fn-20-40 {
  font: normal normal 600 1.250em/2.500rem Open Sans;
}

.fb-20 {
  font: normal normal bold 1.250em/1.688rem Open Sans;
}

.fb-22 {
  font: normal normal bold 1.375em/1.875rem Open Sans;
}

.f600-22 {
  font: normal normal 600 22px/30px Open Sans;
}

.fn-22 {
  font: normal normal normal 1.375em/1.875rem Open Sans;
}

.fb-23 {
  font: normal normal bold 1.438em/2.000rem Open Sans;
}

.fn-24 {
  font: normal normal normal 1.500em/2.063rem Open Sans;
}

.fb-25 {
  font: normal normal bold 1.563em/2.125rem Open Sans;
}

.fn-25 {
  font: normal normal normal 1.563em/2.125rem Open Sans;
}

.fb-28 {
  font: normal normal bold 1.750em/2.375rem Open Sans;
}

.fb-30 {
  font: normal normal 600 1.875em/2.562rem Open Sans;
}

.fn-33 {
  font: normal normal normal 2.063em/2.813rem Open Sans;
}

.fb-34 {
  font: normal normal 800 2.125em/2.875rem Open Sans;
}

.fn-35 {
  font: normal normal normal 2.188em/2.938rem Open Sans;
}

.fb-36 {
  font: normal normal bold 2.250em/3.063rem Open Sans;
}

.fb-40 {
  font: normal normal bold 2.500em/3.438rem Open Sans;
}

.fn-50 {
  font: normal normal normal 3.125em/4.250rem Open Sans;
}

.fb-50 {
  font: normal normal bold 3.125em/4.250rem Open Sans;
}

.fb-49 {
  font: normal normal 800 3.0625em/4.125rem Open Sans;
}

.f600-49 {
  font: normal normal 600 3.0625em/4.125rem Open Sans;
}

.fb-59 {
  font: normal normal bold 3.688em/5.000rem Open Sans;
}

.fb-73 {
  font: normal normal 800 4.562em/6.187rem Open Sans;
}

.fb-79 {
  font: normal normal bold 4.938em/6.688rem Open Sans;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-black {
  color: var(--black-color);
}

.text-white {
  color: var(--white-color);
}

.text-primary {
  color: var(--primary-color);
}

.underline {
  position: relative;
}

.underline::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-radius: 20px;
  background-color: var(--secondary-color);
}

.price-big {
  font-size: 5em;
  line-height: 0.8em;
  font-weight: 800;
}

@media screen and (max-width: 1024px) {
  .text-center-mobile {
    text-align: center;
  }
  .jc-fc-mobile {
    justify-content: center;
  }

  .mobile-p0{
    padding: 0px;
  }
}

.content-item {
  padding: 25px;
  border-radius: 20px;
  border: 1px solid var(--primary-color);
  transition: var(--transition);
  background-color: var(--white-color);
}

.content-item.gray {
  border: 1px solid var(--border-color-o3);
}

.content-item.v-pd {
  padding: 40px 20px;
}

.content-item.no-pd {
  padding: 0;
}

.os{
  overflow: scroll;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

/* Buttons */

.btn {
  display: inline-block;
  padding: 15px 20px;
  border-radius: 100px;
  //font-size: 1em;
  transition: var(--transition);
  border: 0;
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .btn {
    padding: 15px;
  }
}

.btn.mid {
  padding: 15px 20px;
}

.btn.big {
  font-size: 1.2em;
  padding: 18px 40px;
}

.btn.full {
  display: block;
  width: 100%;
  text-align: center;
}

.btn.bordered {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color);
}

.btn.bordered.gray {
  border: 1px solid var(--border-color-o3);
  color: var(--black-color);
}

.btn.bordered:hover, .btn.bordered.active {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.btn.bordered.light-hover:hover, .btn.bordered.light-hover.active,
.btn.bordered.gray.light-hover:hover, .btn.bordered.gray.light-hover.active {
  border: 1px solid var(--primary-color);
  background-color: var(--light-hover-color);
  color: var(--secondary-color);
}

.btn.gray:hover {
  background-color: var(--border-color);
  color: var(--white-color);
}

.btn.action {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.btn.action:hover, .btn.action.active {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.btn.action.dark {
  background-color: var(--secondary-color);
}

.btn.action.dark:hover, .btn.action.dark.active {
  background-color: var(--primary-color);
}

.btn.wish {
  border: 1px solid var(--wish-color);
  color: var(--wish-color);
}

.btn.wish .btn-t {
  width: 92px;
}

.btn.wish:hover, .btn.wish.active {
  background-color: var(--wish-color);
  color: var(--white-color);
}

.btn.wish:hover i::before, .btn.wish.active i::before,
.btn.share:hover i::before, .btn.share.active i::before,
.btn.bordered:hover i::before, .btn.bordered.active i::before {
  color: var(--white-color);
}

.btn.favorite {
  background-color: var(--wish-color) !important;
  color: var(--white-color) !important;
}

.btn.share {
  border: 1px solid var(--info-color);
  color: var(--info-color);
}

.btn.share:hover, .btn.share.active {
  background-color: var(--info-color);
  color: var(--white-color);
}

.btn.info {
  background-color: var(--info-color);
  color: var(--white-color);
}

.btn.info:hover, .btn.info.active {
  background-color: var(--tag-info-color);
}

.btn.border-gray {
  border: 1px solid var(--border-color-o3);
  color: var(--black-color);
}

.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.h-100 {
  height: 100%;
}

.h-90 {
  height: 90%;
}


/* Buttons */

/* Icons */

.icon{
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.i-16 {
  font-size: 16px;
}

.i-17 {
  font-size: 17px;
}

.i-18 {
  font-size: 18px;
}

.i-20 {
  font-size: 20px;
}

.i-22 {
  font-size: 22px;
}

.i-24 {
  font-size: 24px;
}

.i-36 {
  font-size: 36px;
}

.i-48 {
  font-size: 48px !important;
}

.i-60 {
  font-size: 60px;
}

/* Icons */

.danger-border {
  border-color: var(--primary-color) !important;
}

.loaderx {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--secondary-color);
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--black-color);
}
::-moz-placeholder { /* Firefox 19+ */
  color: var(--black-color);
}
:-ms-input-placeholder { /* IE 10+ */
  color: var(--black-color);
}
:-moz-placeholder { /* Firefox 18- */
  color: var(--black-color);
}

.hr-clear{
  margin-top: 30px;
  border: solid 1px --border-color-o3;
  border-top:none;
}

/* Modal */
.ReactModal__Body--open {
  overflow: hidden !important;
}

.ReactModal__Overlay {
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7) 0% 0% no-repeat;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactModal__Content {
  position: absolute;
  background-color: var(--white-color);
  border-radius: 20px;
  padding: 10px;
  border: 1px solid var(--color-cecece-o3);
  outline: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: auto;
  height: max-content;
  max-height: 100vh;
}

// ALERT

.beauty-alert {
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
  color: green;
  font-weight: 700;
}