.vehicle-content {
    background-color: var(--white-color);
    box-shadow: 0px 3px 11px var(--light-white1);
    border: 1px solid var(--light-grey-1-o5);
    border-radius: 10px;
    .img {
        height: 159px;
        width: 100%;
        box-shadow: 0px 3px 11px var(--light-white1);
        border-radius: 10px;
    }
    a {
        height: calc(100% - 159px);
    }
    .icon {
        position: absolute;
        right: 20px;
        bottom: 20px;
        font-size: 1.5em;
        color: var(--primary-color);
    }
    &:hover .remove {
        display: flex;
    }
    .remove {
        width: 39px;
        height: 39px;
        position: absolute;
        right: 0;
        background-color: var(--white-color);
        box-shadow: 0px 3px 11px var(--light-grey-2-o3);
        border-radius: 100%;
        top: -16px;
        color: var(--primary-color);
        font-size: 1.5em;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .sm-gap-30 {
        grid-gap: 0px !important;
    }
    .mobile-card {
        height: 200px !important;
    }
    .vehicle-content {
        margin-bottom: 20px;
    }
}
