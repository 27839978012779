.favorite {
    background-color: var(--white-color);

    #favorite-banner {
        background-image: url('../../assets/img/logo-light-red.png');
        background-repeat: no-repeat;
        background-position: top 360px left -180px;
        min-height: 855px;

        .container {
            padding-bottom: 65px;
        }
    }
}
