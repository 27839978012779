.modal-offer {
    max-width: 700px;
    padding: 30px 50px 30px !important;
    z-index: inherit;

    h1 {
        font: normal normal 800 30px/41px Open Sans;
    }

    span {
        font: normal normal normal 1.250em/1.688rem Segoe UI;
    }

    a {
        font: normal normal normal 1.250em/1.688rem Segoe UI;
        float: right;
        padding-right: 15px;
    }
    a:hover{
        color:var(--primary-color);
    }
      
}

@media screen and (max-width: 768px) {

    .modal-offer {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}