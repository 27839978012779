.need-help {
  h1.title {
    margin-top: 70px;
    margin-bottom: 30px;
  }
  .question {
    h1 {
      font: normal normal normal 3.688em/5.000rem Open Sans;
      color: var(--primary-color);
    }
  }

  .search-form {
    position: relative;
  }

  .search-form input {
    padding: 0 70px 0 30px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 20px;
    font: normal normal normal 1.563em/2.125rem Open Sans;
    border: 1px solid #0000004D;
    &::placeholder {
      color: var(--light-grey-2);
    }
  }

  .search-form .icon {
    position: absolute;
    top: calc(50% - 0.813rem);
    right: 40px;
    cursor: pointer;
    font-size: 1.625em;
  }


  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 20px;
    padding: 10px 20px;
  }

  .rbt-menu > li a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: .7rem .7rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:hover {
      color: var(--primary-color);
    }
  }

  .dropdown-item + .dropdown-item {
    border-top: 1px solid rgba(0,0,0,.15);
  }

  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
  }

  @media screen and (max-width: 768px) {
    .search-form input {
      height: 50px !important;
      padding: 0 50px 0 20px !important;
    }
  }
}