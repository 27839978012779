.home {
    background-color: #fff;
}

#main-banner {
    background-image: url('../../assets/img/logo-light-red.png');
    background-repeat: no-repeat;
    background-position: top 150px left -180px;
    min-height: 930px;

    .container {
        padding-bottom: 65px;
    }

    .images {
        background-image: url('../../assets/img/vehicle1.png'),
         url('../../assets/img/vehicle-brands/light-lambroguini.png'),
         url('../../assets/img/vehicle-brands/light-volvo.png'),
         url('../../assets/img/vehicle-brands/light-lexus.png'),
         url('../../assets/img/vehicle-brands/light-land-rover.png');
        background-repeat: no-repeat;
        background-position: top 0px left -400px,
         bottom 100px left 960px,
         bottom 90px left 1135px,
         bottom 110px left 1320px,
         bottom 110px left 1480px;
        height: 380px;
    }
}

#main-banner .main-title {
    font: normal normal bold 3.688em/5.000rem Open Sans;
}

#benefits {
    background-image: url('../../assets/img/young-woman.png'), url('../../assets/img/home-way1.png');
    background-repeat: no-repeat;
    background-size: auto 100%, auto auto;
    background-position: top -60px left calc(100vw - 1920px), top 34px right 0;
    min-height: 1062px;
    margin: 180px 0 130px;

    .container {
        max-width: 920px;
        margin: 70px 0 0;
        padding: 40px 110px 40px 80px;
        float: right;
        color: var(--white-color);

        h1 {
            padding-right: 80px;
        }

        .benefits-list {
            padding: 0 165px 0 45px;
            li{
                img {
                    width: 45px;
                    height: 43px;
                }
            }
        }
    }
}

#more-benefits {
    .benefits-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 24px var(--light-white2);
        border-radius: 20px;
        padding: 45px;
        .icon {
            font-size: 3.375em;
            color: var(--light-red);
            margin-bottom: 25px;
        }
        h4 {
            color: var(--dark-grey-1);
            margin-bottom: 40px;
        }
    }
}

.banner {
    background: transparent url('../../assets/img/close-up-sales-manager.png') 0% 0% no-repeat padding-box;
    width: 100%;
    height: 629px;
}

#help {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--border-color-2);
    text-align: center;
    padding: 75px;
    color: var(--white-color);
    margin-bottom: 190px;

    .button-help {
        height: 73px;
        padding: 20px;
    }
}

@media screen and (max-width: 877px) {
    #benefits {
        .container {
            h1 {
                padding-right: 0;
            }

            .benefits-list{
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    #benefits {
        .container {
            padding: 40px 20px;
        }
    }
}

@media screen and (max-width: 510px) {
    #benefits {
        .container {
            padding: 20px;
            margin: 40px 0 0;

            h1 {
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 443px) {
    #benefits {
        .container {
            h1 {
                margin-bottom: 10px;
                font: normal normal bold 1.750em/2.375rem Open Sans
            }

            .benefits-list{
                grid-gap: 10px;
            }
        }
    }
}

@media screen and (max-width: 336px) {
    #benefits {
        .container {
            .benefits-list{
                li {
                    img {
                        margin-right: 10px;
                        width: 25px;
                        height: 23px;
                    }
                }
            }
        }
    }
}

#sell-it {
    text-align: center;
    color: #000;
    margin-bottom: 160px;

    .sell-grid {
      max-width: 950px;
      
      .sell-btn {
        height: 73px;
        padding: 20px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    #benefits {
        margin: 0 0 0;
        min-height: 610px;
    }

    .images {
       display: none;
    }

    #more-benefits{ 
        margin-bottom: 50px !important;
        
        h1{
            padding-top: 20px!important;
            padding-bottom: 10px!important;
            margin-bottom: 10px!important;
        }

        .bg-gap{
            grid-gap: 20px !important;
        }
    }

    #sell-your-car .container {
        margin-bottom: 50px !important;
    }

    .banner{
        display: none !important;
    }

    #help{
        margin-bottom: 50px !important;

        .grid {
            width: 100%;
        }
    }

    #sell-it{
        margin-bottom: 50px !important; 
        padding: 20px;
    }

    .mobile-mb-25{
        margin-bottom: 25px;
    }
}