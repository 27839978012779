.error404{
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}

.error404 .image404{
    margin: 0 auto;
    padding-bottom: 50px;
}

.error404 h2{
    text-align: center;
    width: 100%;
    clear: both;
    padding-bottom: 50px;
}