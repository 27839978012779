.location {
  h1.title {
    margin-top: 70px;
    margin-bottom: 30px;
  }
  .container-location {
    background-image: url("../../assets/img/map1.png");
    width: 100%;
    min-height: 829px;
    padding: 140px 90px;
    word-break: break-word;
    .card-location {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 20px;
      height: fit-content;
      padding: 45px;
      .icon {
        font-size: 1.875rem;
        color: var(--primary-color);
      }
    }
  }
  @media screen and (max-width: 540px) {
    .container-location {
      padding: 70px 30px;
    }
  }
}