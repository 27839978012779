:root {
    --white-color: #FFFFFF;
    --black-color: #000000;
    --primary-color: #F61700;
    --secondary-color: #8CE56D;
    --green-color: #008000;
    --border-color: #ABABAB;
    --border-color-o3: #ABABAB4D;
    --border-color-2: #707070;
    --text-color: #000000;
    --text-color-2: #C4C4C4;
    --text-color-3: #A2A2A2;
    --text-color-4: #210909;
    --light-green: #72E586;
    --light-red: #FC4C4C;
    --snow-color: #F9F9F9;
    --light-white1: #E3E3E329;
    --light-white2: #9A9A9A29;
    --light-grey-1: #DCDCDC;
    --light-grey-1-o5: #DCDCDC80;
    --light-grey-2: #BBBBBB;
    --light-grey-2-o3: #BBBBBB4D;
    --light-grey-2-o5: #BBBBBB80;
    --light-grey-3: #D9D9D9;
    --light-grey-3-o3: #D9D9D94D;
    --grey-color: #868686;
    --green-color-2: #72DE64;
    --dark-grey-1: #1C1C1C;
    --dark-grey-2: #B1B1B1;
    --dark-grey-3: #2E2E2E;
    --transition: all 0.2s ease-in-out 0s;
    --transition-fast: all 0.1s ease-in-out 0s;
}
    