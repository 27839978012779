.car-valuation {
  h1.title {
    margin-top: 70px;
    margin-bottom: 20px;
  }
  form {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid var(--border-color-o3);
    border-radius: 20px;
    padding: 60px 56px;
  }
  .card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 24px var(--light-white2);
    border-radius: 20px;
    padding: 120px 45px 20px;
  }
}

@media screen and (max-width: 768px) {


  .mobile-form{
    padding: 10px!important;
    margin-bottom: 10px!important;
  }
}