footer {
  background-image: url('../../assets/img/logo-white-noname-big.png'), url('../../assets/img/home-way2.png');
  background-repeat: no-repeat;
  background-size: auto 100%, 100% 100%;
  background-position: bottom -70px right -90px, center;
  color: var(--white-color);

  .footer {
    padding-top: 320px;
    padding-bottom: 25px;
  }

  h3 {
    font: normal normal 600 2.188em/2.938rem Open Sans;
  }

  h2 {
    font: normal normal 600 3.438em/4.688rem Open Sans;
  }

  p {
    font: normal normal normal 1.250em/1.688rem Open Sans;
    color: var(--white-color);
  }

  .social-media { 
    .circle {
      background: var(--white-color) 0% 0% no-repeat padding-box;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      text-align: center;

      .icon {
        color: var(--primary-color);
        width: 45px;
        height: 45px;
        padding: 10px;
      }
    }

    .circle:hover {
      background: var(--black-color) 0% 0% no-repeat padding-box;
    }
  }

  .div-privacity {
    margin-top: 97px;
    font: normal normal 600 1.250em/1.688rem Open Sans;
  }  
  
  .whats-link:hover{
color:#25D366!important;
  }
}


@media screen and (max-width: 768px) {
  footer{
    h2{
      font: normal normal 600 2.188em/2.938rem Open Sans;
    }
    .div-privacity:first-child {
        margin-top: 20px!important; 
    }
    .div-privacity {
      margin-top: 10px!important; 
  }
  }
}
