.about-us {
  #about-us{
    background-image: url('../../assets/img/logo-light-red2.png');
    background-repeat: no-repeat;
    background-position: top 20px right 10vw;
    .group-top-about-us {
      width: 40%;
      h1 {
        font: normal normal bold 8em/10.937rem Open Sans;
        line-height: 140px;
      }
    }

    .images {
      background-image: url('../../assets/img/vehicle3.png');
      background-repeat: no-repeat;
      background-position: top 0px left -250px;
      height: 380px;
      position: relative;
      top: 130px;
      margin-bottom: 200px;
    }
  }
  
  .square {
    border: 0.5px solid #BBBBBB;
    width: 170px;
    height: 142px;
    margin: 0 auto 25px;
  }


  .benefits-list {
    padding: 0 0 0 45px;
    border-color: var(--border-color-o3);
    border-style: hidden hidden hidden dashed;
    border-width: 4px;
    &::before{
      content: '';
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: var(--primary-color);
      position: absolute;
      left: -18px;
    }
    &::after{
      content: '';
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: var(--primary-color);
      position: absolute;
      left: -18px;
      bottom: 0;
    }
    li{
      img {
        width: 45px;
        height: 43px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-us #about-us .group-top-about-us{
    width: 100%;
    h1 {
      font: normal normal bold 7em/9.937rem Open Sans;
      line-height: 120px;
    }
  }

  #about-us{
    .group-top-about-us {
      text-align: center!important;
    }
  }
}