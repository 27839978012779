.login {
    background-color: var(--white-color);

    #login-banner {
        background-image: url('../../assets/img/logo-light-red.png');
        background-repeat: no-repeat;
        background-position: top 100px left -180px;
        height: 735px;

        .container {
            padding-bottom: 65px;
            z-index: 1;
            position: relative;
        }

        .images {
            background-image: url('../../assets/img/vehicle1.png');
            background-repeat: no-repeat;
            background-position: top 0px left -400px;
            height: 380px;
            position: relative;
            top: -250px;
        }
        h1 {
            color: var(--primary-color);
        }
        .form {
            max-width: 450px;
            label {
                background-color: transparent;
            }
            input:not([type="range"]), select {
                height: 64px;
                padding: 16px;
            }
        }
        .grid-btn {
            max-width: 580px
        }
    }
}
