.privacy-policy {
  p {
    font: normal normal normal 20px/27px Open Sans;
    margin-bottom: 30px !important;
  }

  h3 {
    font: normal normal bold 1.4em Open Sans;
    margin: 40px 0 30px;
  }

  h5 {
    font: normal normal bold 1.2em Open Sans;
    margin: 30px 0;
  }

  ul.dashed > li {
    font: normal normal normal 20px/27px Open Sans;
    margin-bottom: 30px !important;
  } 

  ul.dashed > li:before {
    content: "-";
    text-indent: -5px;
    margin-right: 10px;
  }
}