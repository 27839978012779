/*a.btn {
 
    height: 50px;
    border-radius: 25px;
    padding: 15px;
    font-family: 'Open Sans', sans-serif;

    .white {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #00a651;
        opacity: 1;
    }

    .md {
        font-size: 18px;
    }
}
*/

a.button {
    text-align: center;
    color: var(--white-color);
    border-radius: 58px;
    .icon {
        margin-right: 10px;
    }
}
a.button, a.button:visited {
    color: var(--white-color);
}
  
.btn-t {
    height: 100%;
    display: flex;
    align-items: center;
}

.btn-bg {
    max-width: 465px;
    width: 100%;
    padding: 24px !important;
}

.btn-red {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
}

.btn-red:hover, .btn-red:visited:hover {
    color: var(--black-color);
}

.btn-green {
    background: #4caf50 0% 0% no-repeat padding-box;
}

.btn-green:hover, .btn-green:visited:hover {
    color: var(--black-color);
}

.btn-black {
    background: var(--black-color) 0% 0% no-repeat padding-box;
}

.btn-white {
    background: var(--white-color) 0% 0% no-repeat padding-box;
    color: var(--primary-color) !important;
}

.btn-account {
    background: var(--white-color) 0% 0% no-repeat padding-box;
    color: var(--black-color) !important;
    border: 1px solid #0000004D !important;
    width: 200px;
}

.c-black {
    color: var(--black-color) !important;
}

.btn-account:hover, .btn-account:visited:hover {
    color: var(--primary-color) !important;
}

.btn-h64 {
    height: 64px !important;
    padding: 17px !important;
}

.btn-h60 {
    height: 60px !important;
    padding: 16px !important;
}