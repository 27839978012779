.accordion__item {
  border: 1px solid #0000004D;
  border-radius: 20px;
  margin-bottom: 30px;
}

//.accordion__item + .accordion__item {
//  border-top: 1px solid rgba(0, 0, 0, 0.1);
//}

.accordion__button {
  position: relative;
  cursor: pointer;
  padding: 25px 45px;
  width: 100%;
  border: none;
  border-radius: 20px;
  font: normal normal normal 1.563em/2.125rem Open Sans;
}

.accordion__button:hover {
  //background-color: #0000004D;
}

.accordion__button:before {
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true'],
.accordion__button[aria-selected='true'] {
  border-radius: 20px 20px 0 0;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px 50px;
  font: normal normal normal 20px/27px Open Sans;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
