.advertise {
  #advertise {
    background-image: url('../../assets/img/vehicle2.png');
    background-repeat: no-repeat;
    background-position: top 140px right 150px;

    .advertise-title {
      margin-top: 40px;
      margin-bottom: 53px;
    }

    .form-email {
      width: 33%;
      min-width: 528px;

      .input-email {
        padding: 14px 17px;
        height: 53px;
        width: 295px;
      }

      ::-webkit-input-placeholder { 
        color: var(--text-color-2);
      }
      ::-moz-placeholder { 
        color: var(--text-color-2);
      }
      :-ms-input-placeholder { 
        color: var(--text-color-2);
      }
      :-moz-placeholder { 
        color: var(--text-color-2);
      }

      .icon {
        position: absolute;
        margin-left: -34px;
        margin-top: 18px;
      }

      .button {
        width: 209px;
        height: 53px;
      }

      .div-checkbox {  
        margin-top: 9px;

        label {
          color: var(--text-color-3);
        }
      }
    }

    .ads {
      margin-top: 100px;

      h3 {
        padding: 0 70px;
      }

      .sell-in {
        color: var(--light-green);
      }

      .sell-out {
        color: var(--light-red);
      }

      .currency-br {
        top: -10px;
        position: relative;
        margin-right: 9px;
      }

      .tag-fipe {
        border-radius: 30px;
        padding: 12px 36px;
        width: max-content;
        height: 42px;
        color: var(--white-color);
        margin: 11px auto 18px;
      }

      .tag-green {
        background: var(--light-green) 0% 0% no-repeat padding-box;
      }

      .tag-red {
        background: var(--light-red) 0% 0% no-repeat padding-box;
      }
    }
  }

  #advantages {
    .advantages-grid {
      margin-top: 118px;

      .advantages-card {
        background: var(--white-color) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 11px var(--light-white1);
        border: 1px solid var(--light-grey-1-o5);
        border-radius: 10px;
        padding: 0 23px 36px;
  
        .adv-circle{
          width: 95px;
          height: 95px;
          border-radius: 50%;
          background: var(--white-color) 0% 0% no-repeat padding-box;
          border: 1px solid var(--light-grey-2-o5);
          margin: -45px auto 27px;
          padding: 20px;
  
          .icon {
            font-size: 3.375em;
            color: var(--light-green);
          }
        }
      }
    }
  }

  #sell-it {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--border-color-2);
    text-align: center;
    padding: 50px;
    color: var(--white-color);
    margin-bottom: 160px;

    .sell-grid {
      max-width: 950px;
      
      .sell-btn {
        height: 73px;
        padding: 20px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .advertise {
    #advertise {
      background-position: top 140px right -20vh;
    }
  }
}

@media screen and (max-width: 1230px) {
  .advertise {
    #advertise {
      background-position: top 140px right -40vh;
    }
  }
}

@media screen and (max-width: 985px) {
  .advertise {
    #advertise {
      background-position: top 140px right -60vh;
    }
  }
}

@media screen and (max-width: 835px) {
  .advertise {
    #advertise {
      background: unset;
    }
  }
}

@media screen and (max-width: 548px) {
  .advertise {
    #advertise {
      .form-email {
        min-width: unset;
      }
    }
  }
}