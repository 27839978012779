.search {
    .grid-search {
        margin-top: 25px;
        .search-filters {
            .search-filters-options {
                position: absolute;
                width: 100%;
                top: 0;
                bottom: 0;
                left: -100%;
                z-index: 9;
                background-color: var(--white-color);
                padding: 20px;
                transition: var(--transition);
                border-top: 1px solid #eee;
                border-bottom: 1px solid #eee;
                border-right: 1px solid #eee;

                .group-filters {
                    border-bottom: 1px solid var(--light-grey-3);
                    padding: 0 0 20px;
                }

                .div-car-brand {
                    .close-filters-options {
                        margin-top: 3px;
                        display: none;
                        position: absolute;
                        right: 20px;
                    }

                    .car-brand {
                        background: var(--white-color) 0% 0% no-repeat padding-box;
                        border: 1px solid var(--light-grey-3);
                        border-radius: 10px;
                        width: 100%;
                        max-width: 80px;
                        height: 80px;
                        position: relative;

                        img {
                            margin: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            -ms-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                            width: 90%;
                        }
                    }
                }

                input:not([type="checkbox"]) {
                    height: 64px;
                    padding: 21px 16px;
                    border: 1px solid var(--light-grey-3);
                    &::placeholder {
                        color: var(--light-grey-2);
                    }
                }

                input:not([type="range"]):hover,
                select:hover,
                input:not([type="range"]):focus,
                select:focus {
                    border: 1px solid var(--primary-color);
                }
            }

            .search-filters-options.active {
                left: 0 !important;
            }
        }

        .search-results {
            background: var(--snow-color) 0% 0% no-repeat padding-box;
            height:100%;
            min-height:100vmax;
            .search-filters-btn {
                margin-top: 3px;
                display: none;
            }
        }
    }
    .clear-filter {
        font-size: 11px !important;
        text-align: right;
    }
    .clear-filter:hover {
        color: red !important;
        cursor: pointer;
    }
}

@media screen and (min-width: 1025px) {
    .search .grid-search .search-filters .search-filters-options {
        left: 0 !important;
    }
}

@media screen and (max-width: 1024px) {
    .search .grid-search .search-filters .search-filters-options {
        position: fixed;
        top: 68px;
        overflow: auto;
    }

    .search .grid-search .search-filters .search-filters-options .div-car-brand .close-filters-options {
        display: block;
    }

    .search .grid-search .search-results .search-filters-btn {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .group-filters .grid {
        display: contents !important;
    }

    .mobile-h {
        display: hidden;
    }
    .mobile-none {
        display: none;
    }
    .search-filters-btn {
        font-size: 15px;
    }

    input {
        margin-bottom: 10px;
    }
}
