@import "_colors";
@import "main";

body {
    width: 100%;
    padding: 70px 0 0;
    margin: 0px;
}

.root{
    width: 100%;
    margin: 0px;
    padding:0px;
}

.app{
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.title {
    margin: 110px 0 90px;
    font: normal normal bold 3.688em/5.000rem Open Sans;
}

.body-advertise {
    background-image: url('../img/advertise-way1.png'), linear-gradient(90deg, rgba(249,249,249,1) 0%, rgba(249,249,249,1) 100%);
    background-repeat: no-repeat;
    background-size: auto auto, 100% 100%;
    background-position: top -340px right 50px, center;

    header {
        background-color: transparent;
        a:hover,
        a:visited:hover {
            color: var(--green-color);
        }
    }
    header.shadow {
        background: var(--white-color) 0% 0% no-repeat padding-box;
    }
}

@media screen and (max-width: 1600px) {
    .body-advertise {
        background-position: top -340px right -20vh, center;
    }
}

@media screen and (max-width: 1230px) {
    .body-advertise {
        background-position: top -340px right -40vh, center;
    }
}

@media screen and (max-width: 985px) {
    .body-advertise {
        background-position: top -340px right -60vh, center;
    }
}

@media screen and (max-width: 749px) {
    .body-advertise {
        background: unset;
    }
}

.fake-placeholder {
    color: #B8B8B8 !important;
    option {
        color: black !important;
    }
}