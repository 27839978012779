.vehicle {
  .color-3 {
    color: var(--text-color-3);
  }
  .grey-color {
    color: var(--grey-color);
  }
  .green-color-2 {
    color: var(--green-color-2);
  }
  .vehicle-slider {
    overflow: hidden;
    .vehicle-image {
      background-position: center;
      background-size: cover;
      width: 400px !important;
      height: 400px;
    }
    .swiper-button-next::after, .swiper-button-prev::after {
      color: var(--white-color);
    }
  }

  .container-vehicle {
    top: -70px;
    z-index: 1;
    position: relative;
    .vehicle-div {
      background: var(--white-color) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 11px var(--light-white1);
      border: 1px solid var(--light-grey-1-o5);
      border-radius: 10px;
    }


    .form {
      label {
        background-color: transparent;
      }
      input:not([type="range"]), select {
        height: 64px;
        padding: 16px;
      }
      textarea {
        height: 140px;
        padding: 16px;
        border-radius: 10px;
      }
    }
  }
  .vehicle-description {
    padding: 30px 70px;

  }
  .vehicle-items {
    padding: 30px 70px;
  }
  .about-seller {
    padding: 30px 0 30px 70px;
    .seller {
      img {
        width: 90px;
        height: 90px;
        border-radius: 100%;
      }
    }
    .map {
      position: relative;
      top: -104px;
      margin-bottom: -135px;
      height: max-content;
    }
  }
  .price-comparison {
    padding: 30px 70px;
  }

  .finance {
    padding: 40px 70px;
    input[type="checkbox"]:checked {
      border: 4px solid var(--snow-color);
      background-color: var(--primary-color);
    }

    input[type="checkbox"] {
      width: 32px;
      height: 32px;
      background-color: var(--snow-color);
      padding: 0;
      border: 3px solid transparent;
      box-shadow: 0px 0px 0px 1px var(--border-color);
      appearance: none;
      -webkit-appearance: none;
      border-radius: 100%;
      transition: none;
    }
  }

  .arrow {
    font-size: 8.688em;
    margin-top: 40px;
    color: var(--text-color-3);
  }

  .suggestion-slider {
    overflow: hidden;
    .suggestion-image {
      width: 256px !important;
    }
    .swiper-button-next::after, .swiper-button-prev::after {
      color: var(--primary-color);
      font-weight: bold;
    }
  }



  @media screen and (max-width: 1460px) {
    .vehicle-div .value {
      text-align: center;
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 40px;
      div, a, span {
        width: 100%;
        grid-column: span 1;
      }
    }
  }

  .arrow-down {
    display: none;
  }
  .arrow-right {
    display: block;
  }
  @media screen and (max-width: 1024px) {
    .arrow-down {
      display: block;
    }
    .arrow-right {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .map {
      //top: 0px;
      //margin-bottom: 0px;
      display: none;
    }

    .vehicle-div {
      padding: 30px;
    }
  }
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    pointer-events: auto;
  }

  .vehicle-content {
    margin-bottom: 0;
  }
}